import type { MessageEventOutData } from "@/protoc";
import { getParentOrigin } from "@deputy-ai/common";

export function postParentMessage(data: MessageEventOutData) {
  console.debug(`app integration sent message event', ${data.type}`);
  const parentOrigin = getParentOrigin();
  if (parentOrigin) {
    window.parent.postMessage(data, parentOrigin);
  }
}

export function dismissMessenger() {
  postParentMessage({
    type: "dismiss_messenger",
  });
}
