"use client";
import { jsxs as L, jsx as c } from "react/jsx-runtime";
import "../MessagePanelV2/MessagePanel.js";
import { MessageInput as O } from "../MessagePanelV2/components/MessageInput/index.js";
import "@deputy-ai/api/api";
import "@deputy-ai/api/api/gen/models/MessageParticipantType";
import "@deputy-ai/api/model/message";
import "@deputy-ai/api/model/visitor";
import { forwardRef as W, useState as I, useRef as B, useMemo as G } from "react";
import "uuid";
import "../realtime/PusherProvider.js";
import "@deputy-ai/api/api/gen/models/TaskRealm";
import "@deputy-ai/api/api/singleton";
import "@deputy-ai/api/api/sse";
import "@deputy-ai/api/model/response";
import "@deputy-ai/api/api/request";
import "@deputy-ai/api/model/list";
import "ahooks";
import { MessageContainer as H } from "./components/MessageContainer.js";
import { useLoadMessages as J } from "./hooks/useLoadMessages.js";
import { usePullSSEResult as K } from "./hooks/usePullSSEResult.js";
import { useRealtimeMessage as Q } from "./hooks/useRealtimeMessage.js";
import { MessagingEventType as d, MessagingMessageType as V, MessagingChannelType as X, MessagingStatus as f } from "./messaging/types.js";
import { toMessageMeta as Y } from "./messaging/utils.js";
import { cn as Z } from "@deputy-ai/common";
import { uniqueId as S } from "lodash";
const A = W(
  (w, ee) => {
    const {
      conversationId: i,
      installedApp: t,
      user: M,
      listMessagesApi: b,
      sendMessageApi: x,
      uploadImageApi: C,
      showAvatar: n,
      renderMessageContent: R = (e) => e.payload.message,
      messageBubblePosition: s,
      eventTypes: k,
      realtimeChannelId: N,
      enableRichTextInput: E,
      className: P
    } = w, [q, j] = I(""), [z, p] = I(), l = B(null), { messages: D, loadMore: F, appendMessage: a, removeMessage: m } = J({
      request: b,
      deps: {
        installedAppUid: t == null ? void 0 : t.uid,
        orgUid: t == null ? void 0 : t.orgUid,
        conversationUid: i
      },
      combineDeps: (e) => ({
        path: {
          installedAppUid: e == null ? void 0 : e.installedAppUid,
          orgUid: e == null ? void 0 : e.orgUid,
          conversationUid: e == null ? void 0 : e.conversationUid
        },
        query: {
          page: e == null ? void 0 : e.page,
          pageSize: e == null ? void 0 : e.pageSize,
          eventTypes: k
        }
      }),
      onSuccess: () => {
        setTimeout(
          () => {
            var e;
            return (e = l.current) == null ? void 0 : e.scrollToEnd({ force: !0 });
          },
          0
        );
      }
    }), v = async (e) => {
      const { orgUid: g, uid: U } = t ?? {};
      if (!g || !U)
        return;
      const u = S(), r = {
        uid: u,
        orgUid: g,
        conversationUid: i,
        installedAppUid: U,
        eventType: d.CustomerMessage,
        payload: {
          message: e,
          messageType: V.Text,
          channel: X.Web
        },
        participant: M,
        status: f.Sending,
        eventTime: (/* @__PURE__ */ new Date()).toISOString()
      };
      return a(r), await x(e).then((o) => {
        var h, y, T;
        (h = o == null ? void 0 : o.data) != null && h.message && (m(u), a((y = o == null ? void 0 : o.data) == null ? void 0 : y.message)), o != null && o.error && (m(u), r.status = f.Failed, a(r)), j(((T = o == null ? void 0 : o.data) == null ? void 0 : T.taskUid) ?? "");
      }).catch(() => {
        m(u), r.status = f.Failed, a(r);
      }), await Promise.resolve();
    };
    K({
      appendMessage: (e) => {
        t != null && t.uid && a({
          ...e,
          installedAppUid: t == null ? void 0 : t.uid,
          conversationUid: i,
          uid: S(),
          orgUid: t == null ? void 0 : t.orgUid,
          eventType: d.Ask
        });
      },
      setPendingMessage: (e) => {
        if (!e || !(t != null && t.uid) || !i) {
          p(void 0);
          return;
        }
        p({
          ...e,
          conversationUid: i,
          orgUid: t == null ? void 0 : t.orgUid,
          eventType: d.Ask,
          installedAppUid: t.uid
        });
      },
      taskId: q,
      realm: "human_agent"
    });
    const _ = G(() => {
      var e;
      return `conversation.agent-${(e = t == null ? void 0 : t.settings) == null ? void 0 : e.agent_id}.${i}`;
    }, [t]);
    Q({
      channelId: N ?? _,
      appendMessage: (e) => {
        e.participant.type !== M.type && a(e);
      }
    });
    const $ = (e) => {
      e.uid && (m(e.uid), v(e.payload.message));
    };
    return /* @__PURE__ */ L("div", { className: Z("h-[600px] w-96 overflow-hidden relative", P), children: [
      /* @__PURE__ */ c(
        H,
        {
          ref: l,
          className: "pb-12",
          onRefresh: F,
          messages: D,
          getMessageMeta: (e) => Y(e),
          renderMessageContent: ({ origin: e }) => R(e),
          showAvatar: typeof n == "function" ? ({ origin: e }, g) => n == null ? void 0 : n(e, g) : n,
          showName: !1,
          resendMessage: ({ origin: e }) => $(e),
          messageBubblePosition: ({ origin: e }) => typeof s == "function" ? s == null ? void 0 : s(e) : s,
          pendingMessage: z
        }
      ),
      /* @__PURE__ */ c("div", { className: "absolute -bottom-2 w-full", children: /* @__PURE__ */ c(
        O,
        {
          enableRichText: E,
          uploadImageApi: C,
          sendMessage: async (e) => await v(e.preview)
        }
      ) })
    ] });
  }
);
A.displayName = "Chatbox";
export {
  A as Chatbox
};
