import { usePusher as f } from "./PusherProvider.js";
import { useRef as p, useEffect as l } from "react";
function E(c, e, r) {
  const { subscribe: a, unsubscribe: i } = f(), s = p(r);
  l(() => {
    s.current = r;
  }, [r]), l(() => {
    const t = c.replace(":", "."), u = a(t), o = (n, b) => {
      s.current(n, b);
    };
    return u.bind(e, o), () => {
      u.unbind(e, o), i(t);
    };
  }, [c, e]);
}
function C(c, e, r) {
  const { subscribe: a, unsubscribe: i } = f(), s = p(r);
  l(() => {
    s.current = r;
  }, [r]), l(() => {
    const t = c.replace(":", "."), u = a(t), o = e.map((n) => ({
      eventName: n,
      callback: (b, h) => s.current(n, b, h)
    }));
    return o.forEach((n) => {
      u.bind(n.eventName, n.callback);
    }), () => {
      o.forEach((n) => {
        u.unbind(n.eventName, n.callback);
      }), i(t);
    };
  }, [c, ...e, a, i]);
}
export {
  E as useChannelEvent,
  C as useChannelEvents
};
