import { api as V } from "@deputy-ai/api/api";
import { MessageParticipantType as b } from "@deputy-ai/api/api/gen/models/MessageParticipantType";
import { toApiMessageParam as q } from "@deputy-ai/api/model/message";
import { toVisitorParam as x } from "@deputy-ai/api/model/visitor";
import { useState as B, useRef as C, useEffect as D, useCallback as O } from "react";
import { v4 as U } from "uuid";
function h(I, n, i, t, T) {
  return {
    conversationId: I,
    payload: n,
    messageId: t,
    createTime: Date.now(),
    participant: {
      participantId: `${i.platform}:${i.platformId}`,
      displayName: i.displayName,
      avatar: i.avatarUrl ?? "",
      participantType: i.participateType ?? b.VISITOR
    },
    status: T
  };
}
function G({
  agentId: I,
  conversationId: n,
  user: i,
  addMessage: t,
  knowledgeFilters: T,
  sendMessageApi: v,
  onSuccess: f,
  onError: p
}) {
  const [R, P] = B(!1), y = C(n);
  D(() => {
    y.current = n;
  }, [n]);
  const $ = O(
    async (m) => {
      var N;
      P(!0);
      const c = q(m, i, T), l = U(), r = y.current;
      if (t == null || t(
        h(r, m, i, l, "sending")
      ), v) {
        (N = v(m)) == null || N.then(() => {
          t == null || t(
            h(r, m, i, l)
          ), f == null || f(c);
        }).catch(() => {
          t == null || t(
            h(
              r,
              m,
              i,
              l,
              "error"
            )
          ), p == null || p(c);
        }).finally(() => P(!1));
        return;
      }
      V.clientNoAuth.endpoints.sendMessage({
        conversationId: r,
        endpointId: `agent-${I}`,
        requestBody: {
          message: c,
          visitor: x(i)
        }
      }).then(() => {
        t == null || t(
          h(r, m, i, l)
        ), f == null || f(c);
      }).catch(() => {
        t == null || t(
          h(r, m, i, l, "error")
        ), p == null || p(c);
      }).finally(() => P(!1));
    },
    [I, n, i, T, v]
  );
  return {
    sending: R,
    sendMessage: $
  };
}
export {
  G as useSendMessage
};
