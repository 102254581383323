import { Node as i, mergeAttributes as l, nodeInputRule as s } from "@tiptap/core";
const u = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/, a = i.create({
  name: "ImagePlaceholder",
  onCreate() {
    this.options.uploadFn;
  },
  addOptions() {
    return {
      inline: !1,
      HTMLAttributes: {}
    };
  },
  inline() {
    return this.options.inline;
  },
  group() {
    return this.options.inline ? "inline" : "block";
  },
  draggable: !0,
  addAttributes() {
    return {
      src: {
        default: null
      },
      alt: {
        default: null
      },
      title: {
        default: null
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: "img[src]"
      }
    ];
  },
  renderHTML({ HTMLAttributes: t }) {
    return [
      "img",
      l(this.options.HTMLAttributes, t)
    ];
  },
  addInputRules() {
    return [
      s({
        find: u,
        type: this.type,
        getAttributes: (t) => {
          const [, , e, r, n] = t;
          return { src: r, alt: e, title: n };
        }
      })
    ];
  }
});
export {
  a as ImagePlaceholder,
  u as inputRegex
};
