import type { IKnowledgeFilter } from "@/protoc";
import { createContext, useContext } from "react";

export interface AgentOptionContextData {
  agentId: string;
  knowledgeFilters: IKnowledgeFilter[];
}

export const AgentOptionContext = createContext<
  AgentOptionContextData | undefined
>(undefined);

export function useAgentOptionContext(): AgentOptionContextData | undefined {
  return useContext(AgentOptionContext);
}
