import { ImageUploaderPlugin as i } from "./ImageUploaderPlugin.js";
import { Plugin as l } from "@tiptap/pm/state";
let e = null;
function h(a) {
  e = new i(a);
  const s = {};
  return new l({
    props: {
      handleDOMEvents: {
        keydown(r) {
          return !(e != null && e.setView(r));
        },
        drop(r) {
          return !(e != null && e.setView(r));
        },
        focus(r) {
          return !(e != null && e.setView(r));
        }
      },
      handlePaste(r, o) {
        return (e == null ? void 0 : e.setView(r).handlePaste(o)) ?? !1;
      },
      handleDrop(r, o) {
        return (e == null ? void 0 : e.setView(r).handleDrop(o)) ?? !1;
      }
    },
    state: {
      init() {
        return s;
      },
      apply(r, o, c, n) {
        const t = r.getMeta("uploadImages");
        if (t) {
          const d = typeof t == "string" || t instanceof File ? [t] : Array.from(t);
          setTimeout(() => {
            d.forEach(
              (f, m) => e == null ? void 0 : e.beforeUpload(f, n.selection.from + m)
            ), r.setMeta("uploadImages", void 0);
          }, 100);
        }
        return s;
      }
    }
  });
}
export {
  h as imageUploader
};
