import "../../realtime/PusherProvider.js";
import { useChannelEvent as _ } from "../../realtime/hooks.js";
import { MessageParticipantType as k } from "@deputy-ai/api/api/gen/models/MessageParticipantType";
import { TaskRealm as x } from "@deputy-ai/api/api/gen/models/TaskRealm";
import { api as A } from "@deputy-ai/api/api/singleton";
import { sendSSEGetRequest as h } from "@deputy-ai/api/api/sse";
import { toIParticipant as w, toIMessage as C, isParticipantVisitor as N, createTypingMessage as D } from "@deputy-ai/api/model/message";
import { isErrorResponse as G, isPendingTextResponse as q, isAgentToolCall as O, isAgentToolCallResult as U, isFinalizedMessageResponse as j } from "@deputy-ai/api/model/response";
import { useState as z, useMemo as H, useCallback as J, useEffect as d } from "react";
function p(t, i) {
  i == null || i === "" || (console.debug("--------->", t, "<----------"), console.debug(i));
}
function V(t, i, o) {
  return o ? `${t}/api/tasks/${i}/realm/${o}/stream_result_sse` : `${t}/api/tasks/${i}/stream_public_result_sse`;
}
function Y({
  agentId: t,
  user: i,
  conversationId: o,
  sseTaskId: a,
  authRequired: v,
  setError: r,
  setPendingMessage: n,
  addFinalizedMessage: u
}) {
  const [l, m] = z(), y = H(() => `conversation.agent-${t}.${o}`, [t, o]);
  _(y, "visitor_task", (c) => {
    m(c);
  });
  const $ = J(
    async (c) => {
      const R = V(
        A.baseUrl,
        c,
        a ? x.HUMAN_AGENT : void 0
      );
      r(void 0);
      try {
        const f = await h({
          url: R,
          authRequired: v
        });
        let s = "";
        for (; ; ) {
          const g = await f.next();
          if (!g)
            break;
          const { response: e } = JSON.parse(
            g.data
          );
          if (G(e)) {
            s = "", r(e.message), n(void 0);
            continue;
          }
          if (q(e)) {
            s = `${s}${e.text}`, r(void 0), n({
              conversationId: o,
              messageId: "pending",
              participant: w(e.participant),
              createTime: Date.now(),
              payload: {
                type: "text",
                text: s,
                preview: s
              }
            });
            continue;
          }
          if (O(e)) {
            p("agent tool call", e);
            continue;
          }
          if (U(e)) {
            p("agent tool result", e);
            continue;
          }
          if (j(e)) {
            n(void 0), p("finalized message", e.message), console.debug(
              "-------------------- Debug Info Started ----------------------"
            );
            const { debug: b } = e.message.payload;
            b && Object.entries(b).forEach(([T, E]) => {
              p(T, E);
            }), console.debug(
              "-------------------- Debug Info Ended ----------------------"
            );
            const S = C(e.message);
            u(S), N(S.participant, i) && n(
              D({
                displayName: t,
                participantId: `agent-${t}`,
                participantType: k.AI_AGENT
              })
            );
          }
        }
      } catch {
      } finally {
        n(void 0);
      }
    },
    [a, r, n, u, i, t]
  );
  d(() => {
    m(a);
  }, [a]), d(() => {
    l && $(l);
  }, [l]);
}
export {
  Y as usePullSSEResult
};
