import { jsx as r, jsxs as t } from "react/jsx-runtime";
import { Button as m } from "@deputy-ai/ui/button/Button";
import { Popover as o } from "@deputy-ai/ui/popover/Popover";
import c from "@emoji-mart/data";
import n from "@emoji-mart/react";
import { forwardRef as s } from "react";
import { HiOutlineFaceSmile as a } from "react-icons/hi2";
const p = s(
  (i, d) => {
    const { onPicker: e } = i;
    return /* @__PURE__ */ r("div", { children: /* @__PURE__ */ t(o, { children: [
      /* @__PURE__ */ r(o.Trigger, { asChild: !0, children: /* @__PURE__ */ r(
        m,
        {
          size: "sm",
          variant: "ghost",
          icon: /* @__PURE__ */ r(a, {})
        }
      ) }),
      /* @__PURE__ */ r(o.Content, { className: "w-auto p-0 border-0", children: /* @__PURE__ */ r(n, { data: c, onEmojiSelect: e }) })
    ] }) });
  }
);
p.displayName = "EmojiPicker";
export {
  p as EmojiPicker
};
