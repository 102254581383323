import { redirect, type RouteObject } from "react-router-dom";

export const loadFns: Array<() => Promise<React.ComponentType>> = [];

export function importAsRoute(
  path: string,
  loadFn: () => Promise<React.ComponentType>
): RouteObject {
  loadFns.push(loadFn);
  return {
    path,
    async lazy() {
      return {
        Component: await loadFn(),
      };
    },
  };
}

export function redirectRoute<T>(
  path: string,
  urlOrFactory: string | ((params: T) => string)
): RouteObject {
  return {
    path,
    loader: ({ params }) => {
      if (typeof urlOrFactory === "string") {
        return redirect(urlOrFactory);
      }
      return redirect(urlOrFactory(params as T));
    },
  };
}
