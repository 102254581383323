"use client";

import { AgentOptionContext } from "./component/AgentOptionContext";
import { useAgentOptions } from "@/hook/useAgentOptions";
import { useLoadAgentOptions } from "@/hook/useLoadAgentOptions";
import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";

export default function Layout() {
  const navigate = useNavigate();
  const loadAgentOptions = useLoadAgentOptions();
  const agentOptions = useAgentOptions(loadAgentOptions);

  useEffect(() => {
    if (agentOptions?.agentId) {
      navigate(`/agent/${agentOptions.agentId}`);
    }
  }, [agentOptions?.agentId, navigate]);

  return (
    <AgentOptionContext.Provider value={agentOptions}>
      <Outlet />
    </AgentOptionContext.Provider>
  );
}
