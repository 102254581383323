import { jsx as T, jsxs as b } from "react/jsx-runtime";
import { Loading as B } from "../../../Loading/Loading.js";
import { TIME_BAR_MINUTES_DIFFERENCE as D } from "../../utils/index.js";
import { MessageBubble as F } from "../MessageBubble/index.js";
import { differenceInMinutes as _ } from "date-fns";
import j from "lodash/throttle";
import { useRef as f, useLayoutEffect as A } from "react";
const z = ({
  messages: n,
  user: s,
  pendingMessage: d,
  autoscroll: h,
  isLoading: i,
  hasMore: x,
  loadMore: u,
  renderSlot: I,
  children: v
}) => {
  const l = (r, o) => {
    var E, S, w, N, y;
    const a = (S = (E = n[o - 1]) == null ? void 0 : E.participant) == null ? void 0 : S.participantId, m = (N = (w = n[o + 1]) == null ? void 0 : w.participant) == null ? void 0 : N.participantId, c = (y = r.participant) == null ? void 0 : y.participantId;
    return c !== a && c === m ? "first" : c === a && c !== m ? "last" : c === a && c === m ? "middle" : "sole";
  }, e = (r, o) => {
    var c;
    const a = (c = n[o - 1]) == null ? void 0 : c.createTime, m = r.createTime;
    return !a || !m ? 0 : _(m, a);
  }, { handleScroll: p, bottomElRef: t } = H({
    messages: n,
    pendingMessage: d,
    autoscroll: h,
    isLoading: i
  });
  return /* @__PURE__ */ T("div", { className: R.container, children: /* @__PURE__ */ b("div", { className: R.content, onScroll: p, children: [
    !i && x && u && /* @__PURE__ */ T(
      "a",
      {
        onClick: (r) => {
          r.preventDefault(), u();
        },
        className: "text-center text-xs cursor-pointer text-indigo-400",
        children: "Load more"
      }
    ),
    i && /* @__PURE__ */ T(B, { className: "text-text-indigo-600" }),
    n.map((r, o) => /* @__PURE__ */ T(
      F,
      {
        message: r,
        user: s,
        showTimeBar: o === 0 || e(r, o) >= D,
        order: l(r, o),
        renderSlot: I
      },
      r.messageId
    )),
    v,
    /* @__PURE__ */ T("div", { ref: t })
  ] }) });
}, R = {
  container: "w-full flex flex-col items-center bg-white overflow-y-auto p-3 flex-1",
  content: "relative w-full flex flex-col"
};
function H({
  messages: n,
  pendingMessage: s,
  autoscroll: d,
  isLoading: h
}) {
  const i = f(null), x = f(), u = f(!0), I = f(0);
  function v(t) {
    const r = t.currentTarget.scrollTop;
    u.current ? u.current = r > I.current : u.current = t.currentTarget.scrollHeight - r === t.currentTarget.clientHeight, I.current = r;
  }
  const l = f(), e = f(), p = f(!0);
  return e.current = () => {
    var r;
    if (h || !i.current || !d || !u.current)
      return;
    const t = `${(r = n.at(-1)) == null ? void 0 : r.messageId}-${(s == null ? void 0 : s.createTime) ?? ""}`;
    t !== x.current && (x.current = t, i.current.scrollIntoView({
      behavior: p.current ? "instant" : "smooth"
    }), p.current = !1);
  }, l.current || (l.current = j(() => {
    var t;
    (t = e.current) == null || t.call(e);
  }, 500)), A(() => {
    var t, r;
    p.current ? (t = e.current) == null || t.call(e) : (r = l.current) == null || r.call(l);
  }, [n, s, d]), {
    handleScroll: v,
    bottomElRef: i
  };
}
export {
  z as MessageList
};
