import { useState as p, useEffect as x } from "react";
function j({
  deps: n,
  request: h,
  combineDeps: g,
  onSuccess: s
}) {
  const [d, i] = p([]), [m, r] = p(1);
  x(() => {
    M(1).then(() => s == null ? void 0 : s());
  }, Object.values(n));
  const M = async (o) => {
    const a = o ?? m, e = {
      ...n,
      page: a,
      pageSize: (n == null ? void 0 : n.pageSize) ?? 20
    }, w = (g == null ? void 0 : g(e)) ?? e;
    return await h(w).then((t) => {
      var l, u, f, v;
      const c = d.toSpliced(
        0,
        0,
        ...((u = (l = t == null ? void 0 : t.data) == null ? void 0 : l.messages) == null ? void 0 : u.toReversed()) ?? []
      );
      return i(c), (f = t == null ? void 0 : t.data) != null && f.messages && r(a + 1), { noMore: c.length >= ((v = t == null ? void 0 : t.data) == null ? void 0 : v.total) };
    });
  };
  return {
    messages: d,
    loadMore: M,
    appendMessage: (o) => {
      i((a) => a.concat(o));
    },
    removeMessage: (o) => {
      const a = d.findIndex((e) => e.uid === o);
      i((e) => e.toSpliced(a, 1));
    }
  };
}
export {
  j as useLoadMessages
};
