import { getParentOrigin } from "../../../lib-common/dist";
import type {
  LoadAgentOptionsOptionsEventData,
  MessageEventInData,
} from "@/protoc";
import { postParentMessage } from "@/util/message";
import { useEffect, useState } from "react";

export function useLoadAgentOptions() {
  const [loadAgentOptions, setLoadAgentOptions] = useState<
    LoadAgentOptionsOptionsEventData | undefined
  >(undefined);

  useEffect(() => {
    postParentMessage({
      type: "get_load_agent_options",
    });
  }, []);

  useEffect(() => {
    const onmessage = (e: MessageEvent<MessageEventInData>) => {
      if (e.origin !== getParentOrigin()) {
        return;
      }
      console.debug(`app integration received message event', ${e.data.type}`);
      switch (e.data.type) {
        case "load_agent_options":
          setLoadAgentOptions(e.data);
          break;
      }
    };
    window.addEventListener("message", onmessage);
    return () => {
      window.removeEventListener("message", onmessage);
    };
  }, [setLoadAgentOptions]);

  return loadAgentOptions;
}
