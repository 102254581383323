import { shopifyAPI } from "../../../lib-api-integration-shopify/dist";
import type { AgentOptionContextData } from "@/pages/agent/component/AgentOptionContext";
import type { ShopifyIntegrationLoadAgentOptions } from "@/protoc";
import { dismissMessenger } from "@/util/message";
import { ApiError } from "@deputy-ai/api-integration-shopify/gen";
import { useCallback } from "react";

export function useShopifyAgentConfigLoader() {
  return useCallback(
    async (
      options: ShopifyIntegrationLoadAgentOptions
    ): Promise<AgentOptionContextData | undefined> => {
      try {
        const config = await shopifyAPI.client.default.queryShopConfig({
          shopId: options.shopId,
          pagePath: options.pagePath,
          configId: options.configId,
          origin: options.origin,
        });
        if (!config?.enabled) {
          dismissMessenger();
          console.debug("agent config is not enabled on this page");
          return;
        }
        return {
          agentId: config.payload.agent_id,
          knowledgeFilters:
            config.payload.knowledge_filters?.map((kf) => ({
              knowledgeBaseId: kf.knowledge_base_id!,
              documentId: kf.document_id ?? undefined,
            })) ?? [],
        };
      } catch (e) {
        if (e instanceof ApiError) {
          console.error(e.message);
        } else {
          console.error(e);
        }
        // if there's any error dismiss the frame
        dismissMessenger();
      }
    },
    []
  );
}
