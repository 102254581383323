import { jsx as e } from "react/jsx-runtime";
import { classNames as s } from "@deputy-ai/common";
import { useUpdateEffect as S } from "ahooks";
import { memo as N, useState as g } from "react";
const z = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"], d = "inline-flex items-center justify-center rounded-full", y = {
  xs: 20,
  sm: 24,
  md: 32,
  lg: 48,
  xl: 64,
  xxl: 96,
  xxxl: 128
}, E = N(function({
  icon: u,
  text: f,
  imgUrl: m,
  backgroundColor: n,
  className: a,
  size: r,
  style: i,
  ...l
}) {
  const [h, v] = g(m);
  S(() => {
    v(m);
  }, [m]);
  const t = r ? typeof r == "string" ? y[r] : r : void 0, x = t ? Math.floor(t / 2.5) : void 0, c = t && "w-[--size] h-[--size]", o = {
    ...t && { "--size": `${t}px` },
    ...x && { "--text-size": `${x}px` }
  };
  if (f) {
    if (!n) {
      const p = f[0];
      n = z[p.charCodeAt(0) % z.length];
    }
    return /* @__PURE__ */ e(
      "span",
      {
        className: s(d, c, a),
        style: {
          backgroundColor: n,
          ...o,
          ...i
        },
        ...l,
        children: /* @__PURE__ */ e(
          "span",
          {
            className: s(
              "font-medium leading-none text-white",
              x ? "text-[length:var(--text-size)]" : "text-xs"
            ),
            children: f
          }
        )
      }
    );
  }
  return u ? /* @__PURE__ */ e(
    "span",
    {
      className: s(d, c, a),
      style: { backgroundColor: n, ...o, ...i },
      ...l,
      children: u
    }
  ) : h ? /* @__PURE__ */ e(
    "img",
    {
      className: s(
        "inline-block rounded-full",
        c,
        a
      ),
      style: {
        ...o,
        ...i
      },
      src: h,
      alt: "avatar",
      onError: () => {
        v(void 0);
      },
      ...l
    }
  ) : /* @__PURE__ */ e(
    "span",
    {
      className: s(d, c, a),
      style: { ...o, ...i },
      ...l,
      children: /* @__PURE__ */ e(
        "svg",
        {
          className: "rounded-full text-gray-300",
          fill: "currentColor",
          viewBox: "0 0 24 24",
          children: /* @__PURE__ */ e("path", { d: "M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" })
        }
      )
    }
  );
});
export {
  E as Avatar,
  y as AvatarSizes
};
