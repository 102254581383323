import { useApiResponse as P, sendRequest as R } from "@deputy-ai/api/api/request";
import { api as L } from "@deputy-ai/api/api/singleton";
import { toIListResponse as $ } from "@deputy-ai/api/model/list";
import { toIMessage as q } from "@deputy-ai/api/model/message";
import { useMemoizedFn as a } from "ahooks";
import { useState as g, useEffect as p } from "react";
function j({
  agentId: i,
  conversationId: s,
  userId: r,
  pageSize: n
}) {
  const [M, f] = g([]), [m, d] = g(void 0), { response: t, isLoading: k, error: T } = P(() => {
    if (!(!i || !s || !r))
      return R(
        L.client.endpoints.listVisitorMessages({
          endpointId: `agent-${i}`,
          conversationId: s,
          visitorId: r,
          pageToken: m,
          pageSize: n
        })
      ).map((e) => ({
        ...$(
          e,
          q,
          `${s}:${m ?? "-"}`
        ),
        conversationId: s
      }));
  }, [i, s, r, n, m]);
  p(() => {
    f([]);
  }, [s]), p(() => {
    t != null && t.requestId && f((e) => [...t.items.toReversed(), ...e]);
  }, [t]);
  const c = a((e) => {
    f((o) => {
      var u;
      return e.status ? (((u = o.at(-1)) == null ? void 0 : u.messageId) === e.messageId && o.pop(), [...o, e]) : [...o.filter((x) => !x.status), e];
    });
  }), l = a(() => {
    t != null && t.nextPageToken && d(t.nextPageToken);
  });
  return {
    isLoading: k,
    messages: M,
    addMessage: c,
    loadMore: l,
    error: T,
    hasMore: !!(t != null && t.nextPageToken)
  };
}
export {
  j as useLoadMessages
};
