import a from "lightgallery";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lightgallery.css";
import c from "lightgallery/plugins/thumbnail";
const l = "default-lightgallery", o = {};
function m(n = l) {
  return o[n];
}
function f(n) {
  const { id: e = l, images: r, index: i = 0 } = n;
  let t = m(e);
  return t ? t.updateSlides(r, i) : (t = a(d(e), {
    dynamic: !0,
    dynamicEl: r,
    index: i,
    plugins: [c]
  }), o[e] = t), t.openGallery(i), t;
}
function d(n) {
  const e = document.createElement("div");
  return e.id = n, document.body.appendChild(e), e;
}
export {
  m as getImageViewer,
  f as openImageViewer
};
