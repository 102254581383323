import { createContext as n, useContext as s, useContextSelector as o } from "use-context-selector";
const r = n(null), a = r.Provider;
function c() {
  const e = s(r);
  if (!e)
    throw new Error(
      "useMessageContainer must be used inside MessageContainerProvider"
    );
  return e;
}
function i(e) {
  return o(r, (t) => {
    if (!t)
      throw new Error(
        "useMessageContainerSelector must be used inside MessageContainerProvider"
      );
    return e(t);
  });
}
function C() {
  return (e) => i(e);
}
export {
  a as MessageContainerProvider,
  c as useMessageContainer,
  i as useMessageContainerSelector,
  C as useMessageContainerSelectorHelper
};
