import { routes as agentRoutes } from "./agent/routes";
import ErrorPage from "./error";
import Home from "./index";
import Root from "./root";
import type { RouteObject } from "react-router-dom";

export const routes: RouteObject[] = [
  {
    id: "root",
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        index: true,
        element: <Home />,
      },
      agentRoutes,
    ],
  },
];
