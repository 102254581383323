"use client";
import { jsx as i, jsxs as M } from "react/jsx-runtime";
import { cn as Y } from "@deputy-ai/common";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/plugins/thumbnail";
import "lightgallery/react";
import { openImageViewer as Z } from "../../ImageViewer/imageViewerApi.js";
import _ from "../utils/getToBottom.js";
import { BackToBottom as $ } from "./BackToBottom.js";
import { MessageContainerProvider as L } from "./MessageContainerContext.js";
import { MessagingBubble as j } from "./MessagingBubble.js";
import { ScrollToRefresh as ee } from "./ScrollToRefresh.js";
import { forwardRef as oe } from "@deputy-ai/common/react";
import { useMemoizedFn as F, useCreation as te } from "ahooks";
import { throttle as re } from "lodash";
import { useMemo as z, useState as A, useRef as D, useEffect as V, useImperativeHandle as se } from "react";
function T(l, g) {
  const p = Math.max(l.offsetHeight, 600);
  return _(l) < p * g;
}
const ve = oe(function(g, p) {
  const {
    messages: v,
    getMessageMeta: k,
    isTyping: x,
    onRefresh: q = async () => await Promise.resolve(),
    onScroll: d,
    renderBeforeMessageList: h,
    onBackBottomShow: G,
    onBackBottomClick: B,
    className: J,
    resendMessage: C,
    messageBubblePosition: R,
    showAvatar: S,
    showName: N,
    renderMessageContent: b,
    renderMessageFooter: I,
    pendingMessage: a
  } = g, { convertedMsgs: t, images: c } = z(() => {
    const e = [], o = [];
    for (const s of v ?? []) {
      const X = k(s), u = { ...s, ...X, origin: s };
      e.push(u), u.image && o.push({
        message: u,
        ...u.image
      });
    }
    return {
      convertedMsgs: e,
      images: o
    };
  }, [v]), [w, m] = A(!1), [y, E] = A(0), H = D(null), f = D(null), n = t == null ? void 0 : t[(t == null ? void 0 : t.length) - 1], P = () => {
    E(0), m(!1);
  }, r = F((e) => {
    f.current && (!w || e != null && e.force) && (f.current.scrollToEnd(e), w && P());
  }), K = F(() => {
    r({ animated: !1, force: !0 }), B == null || B();
  }), O = te(
    () => re((e) => {
      if (T(e, 3)) {
        if (y && T(e, 0.5)) {
          P();
          return;
        }
        m(!1);
        return;
      }
      m(!0);
    }),
    []
  ), Q = (e) => {
    O(e.target), d == null || d(e);
  };
  V(() => {
    const e = f.current, o = e == null ? void 0 : e.wrapperRef.current;
    if (!(!o || !n || n.skipScrollToBottom)) {
      if (n.isMe) {
        r({ force: !0 });
        return;
      }
      if (T(o, 2)) {
        const s = !!o.scrollTop;
        r({ animated: s, force: !0 });
        return;
      }
      E((s) => s + 1), m(!0);
    }
  }, [n == null ? void 0 : n.uid]), V(() => {
    r();
  }, [x, r]), se(p, () => ({ ref: H, scrollToEnd: r }), [
    r
  ]);
  const U = (e) => {
    Z({
      images: c.map((o) => ({
        src: o.src,
        thumb: o.src,
        alt: o.alt
      })),
      index: c.findIndex((o) => o.message.uid === e.uid)
    });
  }, W = z(
    () => ({
      messageBubblePosition: R,
      resendMessage: C,
      showAvatar: S,
      showName: N,
      renderMessageContent: b,
      renderMessageFooter: I,
      images: c,
      openImage: U
    }),
    [
      R,
      C,
      S,
      N,
      b,
      I,
      c
    ]
  );
  return /* @__PURE__ */ i(L, { value: W, children: /* @__PURE__ */ M("div", { ref: H, className: Y("h-full relative", J), children: [
    h == null ? void 0 : h(),
    /* @__PURE__ */ i(
      ee,
      {
        onRefresh: q,
        onScroll: Q,
        ref: f,
        children: /* @__PURE__ */ M("div", { className: "px-2 pt-2", children: [
          /* @__PURE__ */ M("div", { children: [
            t == null ? void 0 : t.map((e, o) => /* @__PURE__ */ i(
              j,
              {
                message: e,
                prevMessage: t[o - 1],
                nextMessage: t[o + 1]
              },
              e.uid
            )),
            a && /* @__PURE__ */ i(
              j,
              {
                showTime: !1,
                message: {
                  ...a,
                  ...k(a),
                  origin: a
                }
              }
            )
          ] }),
          x && /* @__PURE__ */ i("div", { children: "Typing..." })
        ] })
      }
    ),
    w && /* @__PURE__ */ i(
      $,
      {
        count: y,
        onClick: K,
        onDidMount: G
      }
    )
  ] }) });
});
export {
  ve as MessageContainer
};
