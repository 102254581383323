var f = Object.defineProperty, g = (o, e, t) => e in o ? f(o, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : o[e] = t, m = (o, e, t) => g(o, e + "", t);
const d = {};
class w {
  constructor(e) {
    this.config = e, m(this, "view");
  }
  handleDrop(e) {
    var i, s;
    if (!((s = (i = e.dataTransfer) == null ? void 0 : i.files) != null && s.length))
      return;
    const t = this.view.posAtCoords({
      left: e.clientX,
      top: e.clientY
    });
    if (!t)
      return;
    const r = Array.from(e.dataTransfer.files).filter(
      (n) => this.config.accepts.includes(n.type)
    );
    if (r.length)
      return r.forEach((n, l) => {
        this.beforeUpload(n, t.pos + l);
      }), !0;
  }
  handlePaste(e) {
    var s, n;
    const t = Array.from(((s = e.clipboardData) == null ? void 0 : s.items) ?? []), r = (n = e.clipboardData) == null ? void 0 : n.getData("Text");
    if (r != null && r.length)
      return !1;
    const i = t[0];
    return i ? (this.beforeUpload(i.getAsFile(), this.view.state.selection.from), !0) : !1;
  }
  beforeUpload(e, t) {
    const r = this.view.state.tr;
    r.selection.empty || r.deleteSelection(), t < 0 && (t = this.view.state.selection.from);
    const i = this.newUploadingImageNode({ src: e });
    r.replaceWith(t, t, i), this.view.dispatch(r), this.uploadImageForId(e, i.attrs.uploadId);
  }
  newUploadingImageNode(e) {
    var r;
    const t = this.config.id();
    return d[t] = (e == null ? void 0 : e.src) || (e == null ? void 0 : e["data-src"]), (r = this.view.state.schema.nodes.ImagePlaceholder) == null ? void 0 : r.create({
      ...e,
      src: "",
      uploadId: t
    });
  }
  async uploadImageForId(e, t) {
    const r = () => {
      const c = [];
      return this.view.state.doc.descendants((a, h) => {
        a.type.name === "ImagePlaceholder" && a.attrs.uploadId === t && c.push({ node: a, pos: h });
      }), c;
    };
    let i = e;
    typeof i == "string" && (i = await p(i));
    const s = i && await this.config.upload(i, t).catch(console.warn), n = r();
    if (!n.length)
      return;
    const l = this.view.state.tr;
    n.forEach(({ node: c, pos: a }) => {
      var u;
      const h = (u = this.view.state.schema.nodes.image) == null ? void 0 : u.create({
        ...c.attrs,
        width: c.attrs.width,
        src: s || ""
      });
      h && l.replaceWith(a, a + 1, h);
    }), this.view.dispatch(l), d[t] = "";
  }
  setView(e) {
    return this.view = e, this;
  }
}
async function p(o) {
  async function e(r) {
    let i = document.createElement("canvas");
    const s = i.getContext("2d"), n = new Image();
    return n.crossOrigin = "Anonymous", n.setAttribute("referrerpolicy", "no-referrer"), n.src = r, await new Promise((l, c) => {
      n.onload = function() {
        if (i === null)
          return;
        i.height = n.height, i.width = n.width, s == null || s.drawImage(n, 0, 0);
        const a = i.toDataURL("image/png");
        l(a), i = null;
      }, n.onerror = c;
    });
  }
  function t(r, i) {
    const s = r.split(","), n = s[0].match(/:(.*?);/)[1], l = n.split("/")[1], c = atob(s[1]);
    let a = c.length;
    const h = new Uint8Array(a);
    for (; a--; )
      h[a] = c.charCodeAt(a);
    return new File([h], `${i}.${l}`, { type: n });
  }
  return await e(o).then((r) => t(r, "image")).catch(() => null);
}
function I(o) {
  return d[o];
}
export {
  w as ImageUploaderPlugin,
  I as getFileCache
};
