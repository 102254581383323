import { VisitorPlatform as c } from "@deputy-ai/api/api/gen/index";
import { useState as l, useCallback as f, useEffect as u } from "react";
import { v4 as m } from "uuid";
const i = "deputy-visitor";
function d() {
  const [r, o] = l(), e = f(
    (t) => {
      o(t), a(t);
    },
    [o]
  );
  return u(() => {
    o(s());
    const t = (n) => {
      n.key === i && o(s());
    };
    return window.addEventListener("storage", t), () => {
      window.removeEventListener("storage", t);
    };
  }, [o]), { visitor: r, updateVisitor: e };
}
function s() {
  const r = localStorage.getItem(i);
  if (r)
    try {
      const t = JSON.parse(r);
      if (t.platform && t.platformId)
        return t;
    } catch (t) {
      console.error("fail to parse visitor storage value", t);
    }
  const o = m(), e = {
    platform: c.WEB_CHAT,
    platformId: o,
    displayName: `Visitor ${o}`
  };
  return a(e), e;
}
function a(r) {
  return localStorage.setItem(i, JSON.stringify(r));
}
export {
  s as readVisitorFromLocalStorage,
  a as saveVisitorToLocalStorage,
  d as useLocalVisitor
};
