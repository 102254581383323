import { jsxs as A, jsx as s } from "react/jsx-runtime";
import { MessageBubble as C } from "./components/MessageBubble/index.js";
import { MessageInput as R } from "./components/MessageInput/index.js";
import { MessageList as j } from "./components/MessageList/index.js";
import { useSendMessage as z } from "./hooks/useSendMessage.js";
import { usePullSSEResult as F } from "./hooks/usePullSSEResult.js";
import { useRealtimeMessage as V } from "./hooks/useRealtimeMessage.js";
import { useLoadMessages as _ } from "./hooks/useLoadMessages.js";
import { DEFAULT_PAGE_SIZE as $, getChannelId as B } from "./utils/index.js";
import { forwardRef as D, useState as g, useRef as G, useImperativeHandle as H } from "react";
const I = D(
  ({
    agentId: r,
    conversationId: o,
    user: e,
    sendMessageApi: c,
    onSendMessageSucceed: p,
    onSendMessageFailed: u,
    sseTaskId: M,
    authRequired: h,
    uploadImageApi: w,
    renderSlot: m,
    messagePageSize: x = $
  }, E) => {
    const [S, P] = g(""), [i, v] = g(), t = G(null), { messages: y, addMessage: n, hasMore: L, loadMore: N, isLoading: b } = _({
      agentId: r,
      conversationId: o,
      userId: `${e.platform}:${e.platformId}`,
      pageSize: x
    }), { sendMessage: d } = z({
      agentId: r,
      conversationId: o,
      user: e,
      sendMessageApi: c,
      addMessage: n,
      onSuccess: p,
      onError: u
    });
    return V({
      channelId: B(r, o),
      onAddMessage: n
    }), F({
      agentId: r,
      conversationId: o,
      user: e,
      sseTaskId: M,
      authRequired: h,
      setError: P,
      setPendingMessage: v,
      addFinalizedMessage: n
    }), H(E, () => ({
      sendMessage: (l) => {
        d({ text: l, type: "text", preview: l });
      },
      setContent: (l) => {
        var a, f;
        return (f = (a = t == null ? void 0 : t.current) == null ? void 0 : a.setContent) == null ? void 0 : f.call(a, l);
      }
    })), /* @__PURE__ */ A("div", { className: "flex flex-col w-full h-full items-center", children: [
      /* @__PURE__ */ s("div", { className: "w-full h-full grow overflow-hidden flex flex-col items-center", children: /* @__PURE__ */ s(
        j,
        {
          user: e,
          messages: y,
          isLoading: b,
          autoscroll: !0,
          hasMore: L,
          loadMore: N,
          renderSlot: m,
          children: i && /* @__PURE__ */ s(
            C,
            {
              message: i,
              user: e,
              order: "sole",
              renderSlot: m
            }
          )
        }
      ) }),
      S && /* @__PURE__ */ s("div", { className: "w-full bg-red-100 text-red-700 text-center p-2", children: "something went wrong, you can try again." }),
      /* @__PURE__ */ s(
        R,
        {
          ref: t,
          sendMessage: d,
          uploadImageApi: w
        }
      )
    ] });
  }
);
I.displayName = "MessagePanelV2";
export {
  I as MessagePanelV2
};
