import { routes as conversationRoutes } from "./[conversationId]/routes";
import Layout from "./layout";
import { importAsRoute } from "@/util/route";
import type { RouteObject } from "react-router-dom";

export const routes: RouteObject = {
  path: ":agentId",
  children: [
    importAsRoute("", async () => (await import("./index")).default),
    conversationRoutes,
  ],
  Component: Layout,
};
