import { Token } from "@deputy-ai/api/api/auth/token";

export function getEnvironment(): "local" | "dev" | "prod" | "unknown" {
  if (location.host.includes("localhost")) {
    return "local";
  }
  if (location.host.includes("dev")) {
    return "dev";
  }
  if (location.host.includes("prod") || location.host.includes("mydeputy.ai")) {
    return "prod";
  }
  return "unknown";
}

export function getApiEndpoint() {
  switch (getEnvironment()) {
    case "local":
      return "";
    case "dev":
      return "https://api-dev-app.gccloud.ai";
    case "prod":
      return "https://api-app.mydeputy.ai";
    default:
      return `https://api-${location.host}`;
  }
}

export function getApiUrl(path: string) {
  return `${getApiEndpoint()}${path}`;
}

export function getFileUrlLegacy(
  {
    workspaceId,
    knowledgeBaseId,
    documentId,
  }: {
    workspaceId: string;
    knowledgeBaseId: string;
    documentId: string;
  },
  path: string,
  accessToken?: string
): string {
  return `${getApiEndpoint()}/api/workspaces/${workspaceId}/knowledgeBases/${knowledgeBaseId}/documents/${documentId}/files?path=${path}&token=${
    accessToken ?? Token.fromLocalStorage().accessToken
  }`;
}

export interface PusherConfig {
  key: string;
  wsHost: string;
  wsPort?: number;
  wssPort?: number;
  forceTLS: boolean;
}

export function getPusherConfig(): PusherConfig {
  switch (getEnvironment()) {
    case "local":
      return {
        key: "app-key",
        wsHost: "127.0.0.1",
        wsPort: 6001,
        forceTLS: false,
      };
    case "dev":
      return {
        key: "app-key-dev",
        wsHost: "soketi.gccloud.ai",
        wssPort: 443,
        forceTLS: true,
      };
    default:
      return {
        key: "app-key-prod",
        wsHost: "soketi.gccloud.ai",
        wssPort: 443,
        forceTLS: true,
      };
  }
}
