import { imageUploader as o } from "./ImageUploader.js";
import { getFileCache as t } from "./ImageUploaderPlugin.js";
import { Extension as a } from "@tiptap/react";
import { v4 as i } from "uuid";
const d = a.create({
  name: "imageUploaderExtension",
  addOptions() {
    return {
      id: i,
      accepts: ["image/*"],
      upload: async () => await Promise.reject(
        new Error("Parameter 'upload' can not be empty!")
      ),
      ignoreDomains: []
    };
  },
  addCommands() {
    return {
      uploadImage: (e) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ({ tr: r }) => (r.setMeta("uploadImages", e.file), !0)
      ),
      getFileCache: (e) => () => t(e)
    };
  },
  addProseMirrorPlugins() {
    const e = this.options;
    return [o(e)];
  }
});
export {
  d as ImageUploaderExtension
};
