import { jsx as e } from "react/jsx-runtime";
import { classNames as r } from "@deputy-ai/common";
import { LoadingIcon as s } from "@deputy-ai/icons";
function n({ className: i, iconClassName: t }) {
  return /* @__PURE__ */ e("div", { className: r("flex items-center justify-center", i), children: /* @__PURE__ */ e(
    s,
    {
      className: r("h-12 w-12", t),
      "aria-hidden": "true"
    }
  ) });
}
export {
  n as Loading
};
