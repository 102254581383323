import "../../realtime/PusherProvider.js";
import { useChannelEvent as e } from "../../realtime/hooks.js";
import { toIMessage as i } from "@deputy-ai/api/model/message";
function f({
  channelId: m,
  onAddMessage: t
}) {
  e(m, "message", (r) => {
    t == null || t(i(r));
  });
}
export {
  f as useRealtimeMessage
};
