import { DeputyAgent } from "./component/DeputyAgent";
import { useUrlParams } from "@/hook/useUrlParams";
import type { AgentUrlParams } from "@/util/url";
import { Outlet } from "react-router-dom";

export default function Layout() {
  const { agentId } = useUrlParams<AgentUrlParams>();
  return (
    <DeputyAgent agentId={agentId}>
      <Outlet />
    </DeputyAgent>
  );
}
