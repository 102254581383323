import { agentIdToEndpointId as o } from "../MessagePanel/util.js";
import { api as l } from "@deputy-ai/api/api";
import { toVisitorParam as m, toApiKnowledgeFilter as d, toIConversation as s } from "@deputy-ai/api/model";
import { useCallback as c } from "react";
function k() {
  return c(
    async (t, a, e, i) => {
      var r;
      const n = await l.client.endpoints.createConversation({
        endpointId: o(t),
        requestBody: {
          id: i,
          config: {
            visitor: m(a),
            knowledge_filters: (r = e == null ? void 0 : e.knowledgeFilters) == null ? void 0 : r.map(d)
          },
          tag_names: e == null ? void 0 : e.tags
        }
      });
      return s(n);
    },
    []
  );
}
export {
  k as useVisitorCreateConversationCallback
};
