import { routes as agentRoutes } from "./[agentId]/routes";
import Layout from "./layout";
import { importAsRoute } from "@/util/route";
import type { RouteObject } from "react-router-dom";

export const routes: RouteObject = {
  path: "agent",
  children: [
    importAsRoute("", async () => (await import("./index")).default),
    agentRoutes,
  ],
  Component: Layout,
};
