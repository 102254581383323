import { MessagingParticipantType as a, MessagingMessageType as r } from "./types.js";
function e(t) {
  var i, p;
  return {
    uid: t.uid,
    participantUid: t.participant.externalId,
    isMe: t.participant.type === a.Visitor || t.participant.type === a.HumanAgent,
    avatarSrc: t.participant.avatar,
    name: t.participant.name,
    eventTime: t.eventTime,
    skipScrollToBottom: t.participant.type === a.System,
    image: t.payload.messageType === r.Image ? {
      // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
      src: (i = t.payload.media) == null ? void 0 : i.mediaUrl,
      alt: (p = t.payload.media) == null ? void 0 : p.altText
    } : void 0
  };
}
export {
  e as toMessageMeta
};
