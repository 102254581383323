import { jsx as i } from "react/jsx-runtime";
import { classNames as o } from "@deputy-ai/common";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lightgallery.css";
import a from "lightgallery/plugins/thumbnail";
import n from "lightgallery/react";
function y(e) {
  const { className: r, children: s, lightGalleryProps: t } = e, { elementClassNames: l, ...m } = t ?? {};
  return /* @__PURE__ */ i(
    n,
    {
      licenseKey: "1F9FF6A9-1086-462B-B958-3A1D35F6B67B",
      elementClassNames: o(r, l),
      plugins: [a],
      ...m,
      children: s
    }
  );
}
export {
  y as ImageViewer
};
