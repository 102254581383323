var t = /* @__PURE__ */ ((r) => (r.Visitor = "visitor", r.AIAgent = "ai_agent", r.HumanAgent = "human_agent", r.System = "system", r))(t || {}), o = /* @__PURE__ */ ((r) => (r.CustomerMessage = "customer_message", r.InternalNote = "internal_note", r.Suggestion = "suggestion", r.Ask = "ask", r.MetricBehavior = "metric_behavior", r.MetricConsumptionTime = "metric_consumption_time", r))(o || {}), m = /* @__PURE__ */ ((r) => (r.Text = "text", r.Image = "image", r.Template = "template", r.File = "file", r.Carousel = "carousel", r.Location = "location", r.List = "list", r.Form = "form", r.FormResponse = "formResponse", r))(m || {}), u = /* @__PURE__ */ ((r) => (r.Chat = "chat", r.Email = "email", r.Web = "web", r))(u || {}), l = /* @__PURE__ */ ((r) => (r.Sending = "sending", r.Sent = "sent", r.Failed = "failed", r.Pending = "pending", r.Typing = "typing", r))(l || {});
function i(r) {
  return r;
}
export {
  u as MessagingChannelType,
  o as MessagingEventType,
  m as MessagingMessageType,
  t as MessagingParticipantType,
  l as MessagingStatus,
  i as toMessagingMessage
};
