import "./i18n";
import "./index.css";
import { routes } from "./pages/routes";
import { getApiEndpoint, getEnvironment, getPusherConfig } from "./util/api";
import { loadFns } from "./util/route";
import { BASE_PATH as SHOPIFY_INTEGRATION_BASE_PATH } from "@deputy-ai/api-integration-shopify";
import { OpenAPI as ShopifyOpenAPI } from "@deputy-ai/api-integration-shopify/gen";
import { OpenAPI } from "@deputy-ai/api/api/gen";
import { PusherProvider } from "@deputy-ai/components";
import * as Sentry from "@sentry/react";
import Pusher from "pusher-js";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "typeface-roboto";

const router = createBrowserRouter(routes);
const apiEndpoint = getApiEndpoint();
const environment = getEnvironment();
const enableSentry = ["prod", "dev"].includes(environment);
console.log(
  `backend endpoint: '${apiEndpoint}', deputy ai environment: ${environment}, enable sentry: ${enableSentry}`
);
OpenAPI.BASE = apiEndpoint;
ShopifyOpenAPI.BASE = apiEndpoint + SHOPIFY_INTEGRATION_BASE_PATH;

const pusherConfig = getPusherConfig();

const pusher = new Pusher(pusherConfig.key, {
  ...pusherConfig,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  cluster: "gccloud",
});

Sentry.init({
  environment,
  enabled: enableSentry,
  dsn: "https://d3f196c85645055d2a546477565b5ed3@o4506013692264448.ingest.sentry.io/4506014817189888",
  integrations: [
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        // "localhost",
        /^https:\/\/api-app\.mydeputy\.ai/,
        /^https:\/\/api-dev-app\.gccloud\.ai/,
      ],
    }),
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Promise.all(loadFns.map(async (fn) => await fn()))
  .then(() => {
    console.log("all page loaded");
  })
  .catch(() => {
    console.log("page load failed");
  });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Suspense fallback="">
      <PusherProvider client={pusher}>
        <RouterProvider router={router} />
      </PusherProvider>
    </Suspense>
  </React.StrictMode>
);
