import { useAgentOptionContext } from "../../component/AgentOptionContext";
import { conversationUrl } from "@/util/url";
import type { IVisitor } from "@deputy-ai/api/model";
import {
  useVisitorCreateConversationCallback,
  type CreateConversationOptions,
} from "@deputy-ai/components";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export function useCreateConversationCallback() {
  const router = useNavigate();
  const createConversation = useVisitorCreateConversationCallback();
  const agentOptionContext = useAgentOptionContext();
  /// TODO:(yun) we need to add varieable to the agentOptionContext to distinguish channel not only used in shopify
  const options = useMemo((): CreateConversationOptions | undefined => {
    if (!agentOptionContext) {
      return;
    }
    return {
      knowledgeFilters: agentOptionContext.knowledgeFilters,
      tags: ["channel:shopify"],
    };
  }, [agentOptionContext]);

  return useCallback(
    async (agentId: string, visitor: IVisitor) => {
      const conversation = await createConversation(agentId, visitor, options);
      router(
        conversationUrl({
          agentId,
          conversationId: conversation.conversationId,
        })
      );
    },
    [createConversation, router, options]
  );
}
