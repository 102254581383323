"use client";

import { useCreateConversationCallback } from "./useCreateConversationCallback";
import { postParentMessage } from "@/util/message";
import type { IVisitor } from "@deputy-ai/api/model";
import { classNames } from "@deputy-ai/common";
import { useLocalVisitor } from "@deputy-ai/components";
import {
  ChatBubbleLeftIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";

export interface DeputyAgentProps {
  children?: React.ReactNode;
  agentId: string;
}

export function DeputyAgent({ children, agentId }: DeputyAgentProps) {
  const [active, setActive] = useState<boolean>(false);
  const { visitor } = useLocalVisitor();
  return (
    <div className="flex h-full flex-col-reverse">
      <div
        className={classNames(
          "grow duration-300 transition-all p-4 relative bottom-0 pb-20 overflow-hidden",
          !active && "hidden"
        )}
      >
        <div className="h-full rounded-lg shadow-xl border-slate-300 border  bg-white">
          {active && children}
        </div>
      </div>
      {visitor && (
        <Toggle
          agentId={agentId}
          visitor={visitor}
          active={active}
          setActive={setActive}
        />
      )}
    </div>
  );
}

interface ToggleProps {
  agentId: string;
  visitor: IVisitor;
  active: boolean;
  setActive: (active: boolean) => void;
}

function Toggle({ agentId, visitor, active, setActive }: ToggleProps) {
  const createConversation = useCreateConversationCallback();
  return (
    <div className="p-4 flex items-center justify-center fixed bottom-0 right-0">
      <button
        className={classNames("dp-float-icon mr-4", !active && "hidden")}
        onClick={(e) => {
          e.preventDefault();
          void createConversation(agentId, visitor);
        }}
      >
        <PlusIcon className="w-6 h-6 " />
      </button>
      <button
        className="dp-float-icon"
        onClick={(e) => {
          e.preventDefault();
          const targetActive = !active;
          setActive(targetActive);
          postParentMessage({
            type: "toggle_messenger",
            active: targetActive,
          });
        }}
      >
        {!active && <ChatBubbleLeftIcon className="w-6 h-6 " />}
        {active && <XMarkIcon className="w-6 h-6 " />}
      </button>
    </div>
  );
}
