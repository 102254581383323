import "../../realtime/PusherProvider.js";
import { useChannelEvent as o } from "../../realtime/hooks.js";
function c({
  channelId: i,
  appendMessage: t
}) {
  o(i, "message", (m) => {
    t == null || t(m);
  });
}
export {
  c as useRealtimeMessage
};
