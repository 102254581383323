import { useShopifyAgentConfigLoader } from "./useShopifyAgentConfig";
import type { AgentOptionContextData } from "@/pages/agent/component/AgentOptionContext";
import type { LoadAgentOptionsOptionsEventData } from "@/protoc";
import { useEffect, useState } from "react";

export function useAgentOptions(
  loadAgentOptions?: LoadAgentOptionsOptionsEventData
) {
  const [options, setOptions] = useState<AgentOptionContextData | undefined>(
    undefined
  );

  const shopifyConfigLoader = useShopifyAgentConfigLoader();

  useEffect(() => {
    if (!loadAgentOptions) {
      setOptions(undefined);
      return;
    }
    const options = loadAgentOptions.options;
    if (options.type === "agent") {
      setOptions(options);
      return;
    }
    if (options.type === "shopify_integration_page_path") {
      void shopifyConfigLoader(options).then((data) => {
        setOptions(data);
      });
    }
    setOptions(undefined);
  }, [setOptions, loadAgentOptions, shopifyConfigLoader]);

  return options;
}
