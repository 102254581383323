import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useSearchParams } from "react-router-dom";

export default function Root() {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const locale = searchParams.get("locale");
    if (locale) {
      void i18n.changeLanguage(locale);
      searchParams.delete("locale");
      setSearchParams(searchParams);
    }
  }, [i18n, searchParams]);

  return (
    <>
      <Outlet />
    </>
  );
}
