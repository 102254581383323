"use client";
import { jsx as t } from "react/jsx-runtime";
import { cn as d } from "@deputy-ai/common";
import * as o from "@radix-ui/react-popover";
import * as m from "react";
const p = o.Trigger, c = o.Anchor, l = o.Close, e = m.forwardRef(({ className: a, align: r = "center", sideOffset: n = 4, ...s }, i) => /* @__PURE__ */ t(o.Portal, { children: /* @__PURE__ */ t(
  o.Content,
  {
    ref: i,
    align: r,
    sideOffset: n,
    className: d(
      "z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      a
    ),
    ...s
  }
) }));
e.displayName = o.Content.displayName;
const v = Object.assign(o.Root, {
  Trigger: p,
  Anchor: c,
  Content: e,
  Close: l
});
export {
  v as Popover,
  e as PopoverContent,
  p as PopoverTrigger
};
