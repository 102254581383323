"use client";
import { jsx as s } from "react/jsx-runtime";
import { Button as o } from "@deputy-ai/ui/button/Button";
import { useEffect as l, useMemo as a } from "react";
import { FiChevronsDown as i } from "react-icons/fi";
const u = ({
  count: e,
  onClick: t,
  onDidMount: r
}) => {
  l(() => {
    r == null || r();
  }, [r]);
  const m = a(() => e > 0 ? `${e} new message${e > 1 ? "s" : ""}` : null, [e]);
  return /* @__PURE__ */ s("div", { className: "absolute rounded-full shadow-md right-4 bottom-16", children: /* @__PURE__ */ s(
    o,
    {
      className: "bg-white rounded-full",
      onClick: t,
      size: "xs",
      variant: "ghost",
      icon: /* @__PURE__ */ s(i, {}),
      children: m
    }
  ) });
};
export {
  u as BackToBottom
};
