import { jsx as f } from "react/jsx-runtime";
import { createContext as b, useContext as p, useRef as P, useCallback as o } from "react";
const n = b(null);
function C() {
  const s = p(n);
  if (!s)
    throw new Error("Pusher client not configured");
  return s;
}
function x({
  children: s,
  client: u
}) {
  const t = P({}), c = o(
    (r) => {
      if (!u || !r)
        throw new Error("Pusher client not configured");
      const e = u.subscribe(r);
      return t.current[r] = [
        ...t.current[r] ?? [],
        e
      ], e;
    },
    [u, t]
  ), i = o(
    (r) => {
      if (!t.current || !u || !r || !(r in t.current))
        return;
      const e = t.current[r];
      e && (e.length === 1 ? (u.unsubscribe(r), delete t.current[r]) : e.pop());
    },
    [t, u]
  );
  return /* @__PURE__ */ f(n.Provider, { value: { client: u, subscribe: c, unsubscribe: i }, children: s });
}
export {
  n as PusherContext,
  x as PusherProvider,
  C as usePusher
};
