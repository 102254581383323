"use client";
const r = typeof window < "u" ? requestAnimationFrame : null;
function l({ el: t, to: o, duration: c = 300, x: i }) {
  let u = 0;
  const n = i ? "scrollLeft" : "scrollTop", f = t[n], e = Math.round(c / 16), a = (o - f) / e;
  if (!r) {
    t[n] = o;
    return;
  }
  function s() {
    t[n] += a, ++u < e && r(s);
  }
  s();
}
export {
  l as default
};
