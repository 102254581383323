"use client";
import { jsx as l, jsxs as M } from "react/jsx-runtime";
import { Loading as k } from "../../Loading/Loading.js";
import { useLatest as O } from "../hooks/useLatest.js";
import $ from "../utils/smoothScroll.js";
import { setTransform as q } from "../utils/styles.js";
import { forwardRef as z, useRef as c, useState as u, useEffect as y, useCallback as h, useImperativeHandle as A, Children as B } from "react";
const F = z((m, S) => {
  const {
    loadingDistance: p = 30,
    children: w,
    onScroll: i,
    onRefresh: H,
    renderIndicator: N = (e) => e === "active" || e === "loading" ? /* @__PURE__ */ l(k, { className: "w-6 h-6" }) : null
  } = m, s = c(null), b = c(null), a = O(H), [j, C] = u(0), [r, T] = u("pending"), [g, D] = u(!m.onRefresh), L = c(r), R = c();
  y(() => {
    L.current = r;
  }, [r]);
  const v = (e) => {
    const o = b.current;
    o && q(o, `translate3d(0px,${e}px,0)`);
  }, f = ({ y: e, animated: o = !0 }) => {
    const t = s.current;
    if (!t) return;
    const n = e === "100%" ? t.scrollHeight - t.offsetHeight : e;
    o ? $({
      el: t,
      to: n,
      x: !1
    }) : t.scrollTop = n;
  }, x = h(
    ({ animated: e = !0 } = {}) => {
      f({ y: "100%", animated: e });
    },
    []
  ), d = h(() => {
    C(0), T("pending"), v(0);
  }, []), E = h(() => {
    const e = s.current;
    if (!(!e || !a.current)) {
      T("loading");
      try {
        const o = e.scrollHeight;
        a.current().then((t) => {
          const n = () => {
            f({
              y: e.scrollHeight - o - 50,
              animated: !1
            });
          };
          clearTimeout(R.current), n(), R.current = setTimeout(n, 0), d(), t != null && t.noMore && D(!0);
        });
      } catch (o) {
        console.error(o), d();
      }
    }
  }, [a, d]), I = (e) => {
    var t;
    i == null || i(e), ((t = e == null ? void 0 : e.currentTarget) == null ? void 0 : t.scrollTop) <= 0 && !g && E();
  };
  return y(() => {
    r === "loading" && v(p);
  }, [p, r]), A(
    S,
    () => ({
      scrollTo: f,
      scrollToEnd: x,
      wrapperRef: s
    }),
    [x]
  ), /* @__PURE__ */ l(
    "div",
    {
      ref: s,
      onScroll: I,
      className: "h-full overflow-y-scroll",
      children: /* @__PURE__ */ M("div", { className: "overflow-hidden min-h-full", children: [
        /* @__PURE__ */ l("div", { children: !g && /* @__PURE__ */ l("div", { className: "flex justify-center", children: N(r, j) }) }),
        B.only(w)
      ] })
    }
  );
});
F.displayName = "ScrollToRefresh";
export {
  F as ScrollToRefresh
};
