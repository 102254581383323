import { jsx as e, jsxs as n, Fragment as x } from "react/jsx-runtime";
import { Avatar as h } from "../../../Avatar/Avatar.js";
import { isParticipantVisitor as y } from "@deputy-ai/api/model/message";
import { timestampToString as m, cn as i } from "@deputy-ai/common";
import { TypingIcon as v, LoadingIcon as w } from "@deputy-ai/icons";
import { Tooltip as N } from "@deputy-ai/ui/tooltip/Tooltip";
import { useMemo as b, useCallback as T, useState as M } from "react";
import { BiSolidErrorCircle as S } from "react-icons/bi";
const z = ({
  message: t,
  user: l,
  order: r,
  showTimeBar: a,
  pending: o,
  renderSlot: s
}) => {
  const c = b(() => y(t.participant, l), [l, t]), f = T(() => {
    if (o ?? t.payload.type === "typing")
      return /* @__PURE__ */ e(v, { className: "h-6 text-gray-600" });
    if (t.payload.type === "text")
      return /* @__PURE__ */ e(
        "span",
        {
          className: "whitespace-pre-line break-all",
          dangerouslySetInnerHTML: {
            __html: t.payload.text
          }
        }
      );
  }, [t, o]), d = b(() => r === "first" || r === "sole", [r]), [p, u] = M(!1);
  return /* @__PURE__ */ n(x, { children: [
    a && t.createTime && /* @__PURE__ */ e("div", { className: "my-2 text-center text-sm text-gray-500", children: m(t.createTime) }),
    /* @__PURE__ */ n(
      "div",
      {
        className: i(
          "flex gap-2 relative",
          c ? "flex-row-reverse ml-12" : "mr-12"
        ),
        children: [
          d ? /* @__PURE__ */ e(N, { content: t.participant.displayName, children: /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            h,
            {
              className: "min-w-[40px] min-h-[40px]",
              imgUrl: t.participant.avatar,
              size: 40,
              text: t.participant.displayName.substring(0, 1)
            }
          ) }) }) : /* @__PURE__ */ e(x, { children: t.createTime && p && /* @__PURE__ */ e(
            "div",
            {
              className: i(
                "absolute text-gray-500 text-sm flex items-center justify-center",
                r === "last" ? "h-[calc(100%-1rem)]" : "h-[calc(100%-0.25rem)]"
              ),
              children: m(t == null ? void 0 : t.createTime)
            }
          ) }),
          /* @__PURE__ */ n("div", { className: i("flex flex-col relative"), children: [
            d && p && t.createTime && /* @__PURE__ */ e(
              "div",
              {
                className: i(
                  "absolute text-gray-500 text-sm top-[-17px]  text-nowrap w-64",
                  c ? "text-right right-0" : "left-0"
                ),
                children: m(t.createTime)
              }
            ),
            t.status === "sending" && /* @__PURE__ */ e(
              w,
              {
                className: i(
                  "h-6 w-6 text-gray-500 absolute left-[-2rem]",
                  r === "last" || r === "sole" ? "bottom-6" : "bottom-4"
                )
              }
            ),
            t.status === "error" && /* @__PURE__ */ e(
              S,
              {
                className: i(
                  "h-6 w-6 text-red-500 absolute left-[-2rem]",
                  r === "last" || r === "sole" ? "bottom-6" : "bottom-4"
                )
              }
            ),
            /* @__PURE__ */ n(
              j,
              {
                isOwn: c,
                order: r,
                onMouseOver: () => u(!0),
                onMouseLeave: () => u(!1),
                children: [
                  f(),
                  s && /* @__PURE__ */ e("div", { children: s(t) })
                ]
              }
            )
          ] })
        ]
      }
    )
  ] });
}, j = ({
  isOwn: t,
  children: l,
  className: r,
  order: a,
  ...o
}) => /* @__PURE__ */ e(
  "div",
  {
    className: i(
      "w-fit max-w-[400px] inline-block px-4 py-2.5 rounded-xl",
      t ? "bg-gray-200" : "bg-slate-200 right-12",
      t ? B[a] : k[a],
      r
    ),
    ...o,
    children: l
  }
), k = {
  first: "rounded-bl-sm mb-1",
  middle: "rounded-l-sm mb-1 ml-12",
  last: "rounded-tl-sm mb-4 ml-12",
  sole: "mb-4"
}, B = {
  first: "rounded-br-sm mb-1",
  middle: "rounded-r-sm mb-1 mr-12",
  last: "rounded-tr-sm mb-4 mr-12",
  sole: "mb-4"
};
export {
  z as MessageBubble
};
