import { jsx as c } from "react/jsx-runtime";
import { ImageViewer as v } from "../../ImageViewer/ImageViewer.js";
import "lightgallery";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/plugins/thumbnail";
import { MessageBlockImage as g } from "./MessageBlockImage.js";
import { classNames as u } from "@deputy-ai/common";
import { forwardRef as w, useRef as f, useImperativeHandle as x, useEffect as d, useState as L } from "react";
import '../../HorizontalImages.css';const M = "_container_16ivw_1", h = {
  container: M
}, b = 400, D = 0.75, I = 200;
function A(t) {
  const { imgList: e } = t, s = f(null), [o, n] = L(b), a = Math.floor(o * D);
  return d(() => {
    if (!s.current) return;
    const r = new ResizeObserver((l) => {
      const m = l[0];
      if (!m) return;
      const { width: p } = m.contentRect;
      n(Math.max(I, p));
    });
    return r.observe(s.current), () => {
      r.disconnect();
    };
  }, []), /* @__PURE__ */ c(_, { ref: s, children: /* @__PURE__ */ c(v, { className: i.content, children: e.map((r, l) => /* @__PURE__ */ c(
    "div",
    {
      className: i.img_wrapper,
      style: { maxWidth: o },
      "data-src": r.src,
      children: /* @__PURE__ */ c(
        g,
        {
          className: u(i.img, r.className),
          style: { maxWidth: o, maxHeight: a, ...r.style },
          draggable: !1,
          viewer: !1,
          ...r
        }
      )
    },
    l
  )) }) });
}
const _ = w(function({ children: e, className: s, ...o }, n) {
  const a = f(null);
  return H({ containerEl: a.current }), x(n, () => a.current), /* @__PURE__ */ c(
    "div",
    {
      ref: a,
      className: u(i.container, h.container, s),
      ...o,
      children: e
    }
  );
}), i = {
  container: "overflow-x-auto pb-1 overscroll-x-none",
  content: "w-max flex flex-row gap-x-4 cursor-grab",
  img_wrapper: "min-w-[100px] bg-[rgba(0,0,0,0.2)] flex justify-center items-center",
  img: "block select-none cursor-zoom-in"
};
function H({
  containerEl: t
}) {
  const { current: e } = f({
    isDown: !1,
    startX: 0,
    scrollLeft: 0,
    isMove: !1
  });
  d(() => {
    t && (t.addEventListener("mousedown", (s) => {
      e.isDown = !0, e.isMove = !1, e.startX = s.pageX - t.offsetLeft, e.scrollLeft = t.scrollLeft;
    }), t.addEventListener("mouseleave", () => {
      e.isDown = !1;
    }), t.addEventListener("mouseup", () => {
      e.isDown = !1;
    }), t.addEventListener(
      "click",
      (s) => {
        e.isMove && s.stopPropagation();
      },
      !0
    ), t.addEventListener("mousemove", (s) => {
      if (!e.isDown) return;
      const n = s.pageX - t.offsetLeft - e.startX;
      e.isMove = e.isMove || Math.abs(n) > 10, t.scrollLeft = e.scrollLeft - n * 3;
    }));
  }, [t]);
}
export {
  A as HorizontalImages
};
