import { jsx as c } from "react/jsx-runtime";
import { openImageViewer as l } from "../../ImageViewer/imageViewerApi.js";
import { classNames as n } from "@deputy-ai/common";
function g(o) {
  const { className: t, src: r, alt: s, viewer: m = !0, onClick: e, ...a } = o;
  return /* @__PURE__ */ c(
    "img",
    {
      className: n(m && "cursor-zoom-in", t),
      src: r,
      alt: s,
      onClick: (...i) => {
        m && l({ images: [{ src: r, alt: s, subHtml: s }] }), e == null || e(...i);
      },
      ...a
    }
  );
}
export {
  g as MessageBlockImage
};
