import { jsxs as l, jsx as n } from "react/jsx-runtime";
import { ImagePlaceholder as Q } from "../../../ImgUploaderExtension/ImagePlaceholder.js";
import { ImageUploaderExtension as X } from "../../../ImgUploaderExtension/ImageUploaderExtension.js";
import { EmojiPicker as H } from "./EmojiPicker.js";
import { cn as F } from "@deputy-ai/common";
import { Button as i } from "@deputy-ai/ui/button/Button";
import { Extension as Y } from "@tiptap/core";
import { Image as Z } from "@tiptap/extension-image";
import { useEditor as _, EditorContent as $ } from "@tiptap/react";
import R from "@tiptap/starter-kit";
import { forwardRef as tt, useState as x, useRef as u, useEffect as T, useImperativeHandle as et } from "react";
import { BiExpandAlt as nt, BiSolidSend as ot, BiBold as rt, BiItalic as it, BiStrikethrough as st, BiCollapseAlt as ct } from "react-icons/bi";
import { IoImageOutline as j } from "react-icons/io5";
const at = tt(
  ({ sendMessage: d, uploadImageApi: k, enableRichText: C, disableSend: G }, L) => {
    const [f, p] = x(!1), [g, h] = x({
      bold: !1,
      italic: !1,
      strike: !1
    }), b = u(null), [y, v] = x(!1), E = u(f);
    T(() => {
      E.current = f;
    }, [f]);
    const s = u(), A = () => {
      if (!e)
        return "";
      if (!C)
        return e.getText();
      const t = /<p>(.*)<\/p>/, o = e.getHTML(), r = t.exec(o);
      return !r || r.length < 2 || !r[1] ? "" : r[1];
    }, B = () => {
      const t = A();
      !e || !t || G || (d == null || d({
        type: "text",
        text: t,
        preview: t
      }), e.commands.clearContent(), p(!1), v(!1));
    };
    s.current = B;
    const w = u();
    w.current || (w.current = Y.create({
      addKeyboardShortcuts() {
        return {
          Enter: ({ editor: t }) => {
            var o;
            return E.current ? (t.commands.keyboardShortcut("Mod-Enter"), !0) : ((o = s.current) == null || o.call(s), !0);
          },
          "Shift-Enter": () => {
            var t;
            return (t = s.current) == null || t.call(s), !0;
          }
        };
      }
    }));
    const e = _({
      editorProps: {
        attributes: {
          class: "h-full w-full outline-none text-gray-700 px-3"
        }
      },
      onUpdate({ editor: t }) {
        t.getText() || v(!1);
      },
      extensions: [
        R,
        Z.configure({ inline: !0 }),
        X.configure({
          upload: async (t) => {
            if (!k)
              throw new Error("uploadApi can't be empty");
            return await k(t).then((o) => o.uri);
          }
        }),
        Q.configure({
          inline: !1
        }),
        w.current
      ]
    });
    et(L, () => ({
      setContent: (t) => e == null ? void 0 : e.commands.setContent(t)
    })), T(() => {
      const t = new ResizeObserver((o) => {
        var z, P;
        const r = (z = o[0]) == null ? void 0 : z.target.clientHeight, m = (P = o[0]) == null ? void 0 : P.target;
        if (!m || !r)
          return;
        const a = window.getComputedStyle(m), M = parseFloat(a.fontSize), S = parseFloat(a.lineHeight), O = isNaN(S) ? M * 1.2 : S, U = parseFloat(a.paddingTop), V = parseFloat(a.paddingBottom), W = parseFloat(a.borderTopWidth), q = parseFloat(a.borderBottomWidth), D = O + U + V + W + q;
        v((J) => J || r > D);
      });
      return b.current && t.observe(b.current), () => t.disconnect();
    }, []);
    const c = u(null), I = /* @__PURE__ */ n($, { editor: e }), K = () => e ? /* @__PURE__ */ l("div", { className: "flex flex-col h-[25rem] m-2 p-2 border rounded transition-all", children: [
      /* @__PURE__ */ l("div", { className: "flex px-3 py-2 justify-between", children: [
        /* @__PURE__ */ l("div", { className: "flex flex-row gap-1", children: [
          /* @__PURE__ */ n(
            i,
            {
              variant: g.bold ? "secondary" : "ghost",
              size: "sm",
              icon: /* @__PURE__ */ n(rt, {}),
              onClick: () => {
                h((t) => ({
                  ...t,
                  bold: !t.bold
                })), e == null || e.chain().focus().toggleBold().run();
              }
            }
          ),
          /* @__PURE__ */ n(
            i,
            {
              variant: g.italic ? "secondary" : "ghost",
              size: "sm",
              icon: /* @__PURE__ */ n(it, {}),
              onClick: () => {
                h((t) => ({
                  ...t,
                  italic: !t.italic
                })), e == null || e.chain().focus().toggleItalic().run();
              }
            }
          ),
          /* @__PURE__ */ n(
            i,
            {
              variant: g.strike ? "secondary" : "ghost",
              size: "sm",
              icon: /* @__PURE__ */ n(st, {}),
              onClick: () => {
                h((t) => ({
                  ...t,
                  strike: !t.strike
                })), e == null || e.chain().focus().toggleStrike().run();
              }
            }
          ),
          /* @__PURE__ */ n(H, { onPicker: N }),
          /* @__PURE__ */ n(
            i,
            {
              variant: "ghost",
              size: "sm",
              icon: /* @__PURE__ */ n(j, {}),
              onClick: () => {
                var t;
                e.commands.focus(), (t = c == null ? void 0 : c.current) == null || t.click();
              }
            }
          )
        ] }),
        /* @__PURE__ */ n(
          i,
          {
            variant: "ghost",
            size: "sm",
            onClick: () => p(!1),
            icon: /* @__PURE__ */ n(ct, {})
          }
        )
      ] }),
      /* @__PURE__ */ n("div", { className: "h-full", onClick: () => e == null ? void 0 : e.commands.focus(), children: I })
    ] }) : null, N = (t) => {
      e == null || e.commands.insertContent(t.native);
    };
    return /* @__PURE__ */ l("div", { className: "w-full bg-white", children: [
      /* @__PURE__ */ n(
        "input",
        {
          ref: c,
          className: "hidden",
          type: "file",
          accept: "image/*",
          onChange: (t) => {
            var r, m;
            const o = (m = (r = t == null ? void 0 : t.target) == null ? void 0 : r.files) == null ? void 0 : m[0];
            t.target.value = "", e == null || e.commands.uploadImage({ file: o });
          }
        }
      ),
      f ? K() : /* @__PURE__ */ l("div", { className: "flex border-gray-200 overflow-hidden items-center border m-2 p-1 rounded relative", children: [
        /* @__PURE__ */ n(
          "div",
          {
            ref: b,
            className: F(
              "flex-1 h-fit max-h-96 overflow-y-auto py-1",
              y && "mb-8"
            ),
            children: I
          }
        ),
        /* @__PURE__ */ l(
          "div",
          {
            className: F(
              "flex flex-row",
              y && "absolute right-1 bottom-1"
            ),
            children: [
              /* @__PURE__ */ n(H, { onPicker: N }),
              /* @__PURE__ */ n(
                i,
                {
                  variant: "ghost",
                  size: "sm",
                  icon: /* @__PURE__ */ n(j, {}),
                  onClick: () => {
                    var t;
                    e == null || e.commands.focus(), (t = c == null ? void 0 : c.current) == null || t.click();
                  }
                }
              ),
              C && /* @__PURE__ */ n(
                i,
                {
                  variant: "ghost",
                  size: "sm",
                  icon: /* @__PURE__ */ n(nt, {}),
                  onClick: () => p(!0)
                }
              ),
              /* @__PURE__ */ n(
                i,
                {
                  variant: "ghost",
                  size: "sm",
                  onClick: B,
                  icon: /* @__PURE__ */ n(ot, {})
                }
              )
            ]
          }
        )
      ] })
    ] });
  }
);
at.displayName = "MessageInput";
export {
  at as MessageInput
};
