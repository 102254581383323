import { MessagingMessageType as c, MessagingChannelType as m, MessagingStatus as R } from "../messaging/types.js";
import { sendSSEGetRequest as _ } from "@deputy-ai/api/api/sse";
import { isErrorResponse as h, isPendingTextResponse as v, isFinalizedMessageResponse as w } from "@deputy-ai/api/model/response";
import { uniqueId as C } from "lodash";
import { useState as u, useEffect as d } from "react";
function L({
  appendMessage: n,
  realm: s,
  taskId: i,
  setPendingMessage: t
}) {
  const [p, g] = u(i), [y, r] = u(null), f = async (S) => {
    const T = $(S, s);
    try {
      const o = await _({ url: T });
      let a = "";
      const x = C();
      for (; ; ) {
        const l = await o.next();
        if (!l)
          break;
        const { response: e } = l.data;
        if (h(e)) {
          a = "", r(e.message), t(void 0);
          continue;
        }
        if (v(e)) {
          console.log("isPendingTextResponse", e), a = `${a}${e.text}`, r(null), t({
            uid: x,
            participant: {
              type: e.participant.participant_type,
              name: e.participant.display_name,
              externalId: e.participant.participant_id
            },
            eventTime: (/* @__PURE__ */ new Date()).toLocaleString(),
            payload: {
              message: a,
              messageType: c.Text,
              channel: m.Chat
            },
            status: R.Pending
          });
          continue;
        }
        if (w(e)) {
          r(null), t(void 0);
          const { text: E } = e.message.payload.data;
          n({
            payload: {
              message: E,
              messageType: c.Text,
              channel: m.Chat
            },
            participant: {
              type: e.message.payload.participant.participant_type,
              name: e.message.payload.participant.display_name,
              externalId: e.message.payload.participant.participant_id
            },
            eventTime: (/* @__PURE__ */ new Date()).toLocaleString()
          });
        }
      }
    } catch {
    } finally {
      t(void 0);
    }
  };
  return d(() => {
    g(i);
  }, [i]), d(() => {
    p && f(p);
  }, [p]), {
    error: y
  };
}
function $(n, s) {
  return `/v1/tasks/${n}/realm/${s}/stream_result_sse`;
}
export {
  L as usePullSSEResult
};
