export interface AgentUrlParams {
  agentId: string;
}

export function agentUrl({ agentId }: AgentUrlParams): string {
  return `/agent/${agentId}`;
}

export interface ConversationUrlParams extends AgentUrlParams {
  conversationId: string;
}

export function conversationUrl(params: ConversationUrlParams): string {
  return `${agentUrl(params)}/${params.conversationId}`;
}
