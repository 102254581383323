"use client";
import { jsx as s, jsxs as u } from "react/jsx-runtime";
import { cn as h } from "@deputy-ai/common";
import * as t from "@radix-ui/react-tooltip";
import { cva as g } from "class-variance-authority";
import * as e from "react";
const P = t.Provider, v = t.Root, x = t.Trigger, R = t.Portal, T = g(
  "z-50 overflow-hidden rounded-md px-3 py-1.5 text-sm shadow-md",
  {
    variants: {
      theme: {
        light: "bg-white text-primary",
        dark: "bg-primary text-primary-foreground border-primary-700"
      },
      arrow: {
        true: "",
        false: "border animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
      }
    }
  }
), l = e.createContext({}), c = e.forwardRef(({ className: r, sideOffset: o = 4, ...a }, i) => {
  const { theme: n, arrow: d } = e.useContext(l);
  return /* @__PURE__ */ s(
    t.Content,
    {
      ref: i,
      sideOffset: o,
      className: h(T({ theme: n, arrow: d }), r),
      ...a
    }
  );
});
c.displayName = t.Content.displayName;
const j = g("", {
  variants: {
    theme: {
      light: "fill-white",
      dark: "fill-primary"
    }
  }
}), f = e.forwardRef(({ className: r, ...o }, a) => {
  const { theme: i } = e.useContext(l);
  return /* @__PURE__ */ s(
    t.Arrow,
    {
      ref: a,
      className: h(j({ theme: i }), r),
      ...o
    }
  );
});
f.displayName = t.Arrow.displayName;
function z({
  children: r,
  content: o,
  open: a,
  defaultOpen: i,
  onOpenChange: n,
  delayDuration: d,
  disableHoverableContent: w,
  disabled: y,
  arrow: m = !1,
  theme: p = "light",
  ...C
}) {
  const b = a ?? (y === !0 ? !1 : void 0) ?? // not open if disabled
  (o === void 0 ? !1 : void 0), N = e.useMemo(() => ({ theme: p, arrow: m }), [p, m]);
  return /* @__PURE__ */ s(l.Provider, { value: N, children: /* @__PURE__ */ u(
    v,
    {
      open: b,
      defaultOpen: i,
      onOpenChange: n,
      delayDuration: d,
      disableHoverableContent: w,
      children: [
        /* @__PURE__ */ s(x, { asChild: !0, children: r }),
        /* @__PURE__ */ u(c, { ...C, children: [
          o,
          m && /* @__PURE__ */ s(f, {})
        ] })
      ]
    }
  ) });
}
const O = Object.assign(z, {
  Provider: P,
  Trigger: x,
  Content: c,
  Portal: R,
  Root: v,
  Arrow: f
});
export {
  O as Tooltip
};
